import { Package } from '../../cargo/types';
import { ContactCast } from '../../cast/types';
import { Phone } from '../../contact/types';
import { WaybillDeliveryType } from '../../shipping/types';
import { TerminalListItem } from '../../terminal/types';

export enum CalculationType {
  SIMPLE,
  FROM_TERMINAL,
  TO_TERMINAL,
  TERMINAL_TERMINAL,
  INTERNATIONAL
}

export enum CalculationSideCastType {
  DOMESTIC,
  TERMINAL,
  FOREIGN
}

export enum CalculationStage {
  SENDER,
  RECEIVER,
  TRANSLITERATE,
  CARGO,
  RESULTS,
  SENDER_TERMINAL,
  RECEIVER_TERMINAL,
  CREATION
}

export enum ContactType {
  DOMESTIC,
  FOREIGN
}

export enum RateGroupTypes {
  FASTEST = 'FASTEST',
  CHEAPEST = 'CHEAPEST',
  OPTIMAL = 'OPTIMAL',
  RECOMMENDED = 'RECOMMENDED',
  DEFAULT = 'DEFAULT'
}

export type BaseContactCast = {
  iso: string,
  postcode: string,
  region?: string,
  district?: string,
  city: string,
  cityType?: string,
  street?: string,
  house?: string,
  office?: string,
  latitude: number,
  longitude: number,
  comment?: string,
  addressLine?: string,

  contactName: string,
  companyName?: string,
  email?: string,

  phones: Phone[],
  inn?: string,
  position?: string,

  contactInfoId?: string,
  counterpartyId?: string,

  side?: 'sender' | 'receiver',

  legalAddress?: string,
  kpp?: string,
  ogrn?: string,

  intercomNumber?: string,
  intercomCode?: string,

  needPass?: boolean,
  needIdCard?: boolean,

  workingFrom?: string,
  workingTo?: string,

  lunchFrom?: string,
  lunchTo?: string,

  preCallPeriod?: number,

  contactCastId?: string,
  addressCastId?: string,
  terminalId?: number,

  passport?: string,
  opfFull?: string,

  fiasId?: string,
};

export type DomesticCast = BaseContactCast & {
  companyNameTransliterate?: string,
  contactNameTransliterate?: string,
  addressTransliterate?: string,
  officeTransliterate?: string,
  commentTransliterate?: string,
  yandexData?: string,

  noHouseNumber?: boolean,
};

export type ForeignContactCast = BaseContactCast & {
  googlePlaceId?: string,
};

export type ContactFormData = {
  iso: string,
  postcode?: string | null,
  region?: string | null,
  district?: string | null,
  city?: string | null,
  cityType?: string | null,
  street?: string | null,
  house?: string | null,
  office?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  cityLatitude?: number | null,
  cityLongitude?: number | null,
  comment?: string | null,
  addressLine?: string | null,
  query?: string | null,

  contactName?: string | null,
  companyName?: string | null,
  email?: string | null,

  phones?: Partial<Phone>[] | null,
  inn?: string | null,
  position?: string | null,
  legalAddress?: string | null,
  kpp?: string | null,
  ogrn?: string | null,

  intercomNumber?: string | null,
  intercomCode?: string | null,

  preCallPeriod?: number | null,

  workingPeriod?: [string, string] | null,
  lunchPeriod?: [string, string] | null,

  needPass?: boolean | null,
  needIdCard?: boolean | null,

  noHouseNumber?: boolean | null,

  terminalId?: number | null,
  terminal?: TerminalListItem | null,

  cityAddressLine?: string | null,
  addressCastId?: string | null,
  
  fiasId?: string | null,
};

export type TerminalCast = {
  iso: string,
  contactName: string,
  companyName?: string,
  email?: string,
  phones: Array<Phone>,
  inn?: string,
  contactCastId: string,
  terminal: TerminalListItem,
  terminalId?: number | null,
  latitude?: number | null,
  longitude?: number | null,
  legalAddress?: string,
  opfFull?: string,
  passport?: string,
  side?: 'sender' | 'receiver',
};

export type CalculationSide = {
  type: CalculationSideCastType.DOMESTIC,
  cast: DomesticCast,
} | {
  type: CalculationSideCastType.FOREIGN,
  cast: ForeignContactCast,
} | {
  type: CalculationSideCastType.TERMINAL,
  cast: TerminalCast,
};

export enum ContactCastType {
  RECENT_CONTACT = 'RECENT_CONTACT',
  COUNTERPARTY = 'COUNTERPARTY'
}

export enum PersonalInfoType {
  PASSPORT = 'PASSPORT',
  COMPANY_INFO = 'COMPANY_INFO'
}

export type CalculationCast = {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  office?: string,
  longitude: number,
  latitude: number,
  comment: string,
  contactName?: string,
  companyName?: string,
  email?: string,
  phones?: Array<Phone>,
  inn?: string,
  addressCastId?: string,
  contactInfoId?: string,
  contactCastId?: string,
  counterpartyId?: string,
  googlePlaceId?: string,
  position?: string,
  legalAddress?: string,
  kpp?: string,
  ogrn?: string,
  cityType?: string,
  intercomNumber?: string,
  intercomCode?: string,
  preCallPeriod?: number,
  workingFrom?: string | null,
  workingTo?: string | null,
  lunchFrom?: string | null,
  lunchTo?: string | null,
  needPass?: boolean,
  needIdCard?: boolean,
  recentId?: string,
  type?: ContactCastType,
  noHouseNumber?: boolean,
  terminalId?: number,
  terminal?: TerminalListItem,
  fiasId?: string | null
};

export type FastCalculationSide = {
  value?: string,
  postcode?: string | null,
  iso: string,
  region?: string | null,
  district?: string | null,
  city?: string | null,
  street?: string | null,
  house?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  cityType?: string | null,
  addressCastId?: string | null,
  fiasId?: string | null,
}

export type FastCalculationForm = {
  sender: FastCalculationSide | null,
  receiver: FastCalculationSide | null,
}

export type FastCalculationContactFormData = {
  sender: ContactFormData,
  receiver: ContactFormData,
  pickupDate: any
}

export type ForeignContactFormData = {
  contactNameTransliterate?: string | null,
  companyNameTransliterate?: string | null,
  officeTransliterate?: string | null,
  commentTransliterate?: string | null,
  legalAddressTransliterate?: string | null,
  addressTransliterate?: string | null,
}

export type FastCalculationForeignContactFormData = {
  sender: ContactFormData & ForeignContactFormData,
  receiver: ContactFormData & ForeignContactFormData,
  pickupDate: any,
  pickupPeriod: any,
}
 
export type FastOrderCast = {
  sender: ContactCast & { terminalId?: number },
  receiver: ContactCast & { terminalId?: number },
  cargo: Package[],
  deliveryType?: WaybillDeliveryType,
  deliveryService: string
}

export enum InvoiceStepsType {
  SIDES = 0,
  CARGO = 1,
  DATES = 2,
  FIN = 3
}